import React from "react"
import "../static/style/componentStyle/Videos.scss"
import VideoCards from "./VideoCards"


const Videos = ({ blogData, resourceUrl }) => {
  return (
    <div className="blog-content">
      <div className="page-wrapper">
        <div className="blog-inner">
            {blogData && blogData?.map((val, idx) => (
              <VideoCards val={val} key={idx}  resourceUrl={resourceUrl}/>
            ))}
          </div>
          <div className="blog-inner-mobile">
            {blogData && blogData?.map((val, idx) => (
              <VideoCards val={val} />
            ))}
          </div>
        </div>
      </div>
    )
}
export default Videos
