import React from "react"
import pryzmVideo from "../static/images/video/Pryzm-video.mp4"
import VideoComponent from "./videoComponent"

const truncateText = (text, maxLength) => {
  if (text?.length > maxLength) {
    return text.substring(0, maxLength) + "..."
  }
  return text
}

const VideoCards = ({ data, idx }) => {
  console.log(data,"King");
  return (
    <>
      <div className="videoCard">
        {/* <div className="page-banner"> */}
          <div className="video-area">
            <div className="video-inner">
              {/* <h3>Pryzm Introduction</h3> */}
              <div className="image-container-header">
                <VideoComponent
                  video={pryzmVideo}
                  poster={"/uploads/pryzm_home_banner_f0f5a79b43.png"}
                />
              </div>
            {/* </div> */}
          </div>
        </div>
        <div className="blog-footer">
          <div className="read-more">
            <p>Pryzm Introduction</p>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  )
}

export default VideoCards
